import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser';
// import Hero from '../assets/imgs/benefits/hero.png'
// import Creativedays from '../assets/imgs/benefits/creativedays.png'
// import DevelopmentPotential from '../assets/imgs/benefits/DevelopmentPotential.png'
// import Teamevents from '../assets/imgs/benefits/teamevents.png'
// import WorkingHours from '../assets/imgs/benefits/workingHours.png'

function JobLayout() {
    const [job, setJob] = useState(undefined);
    const [jobs, setJobs] = useState([]);
    const params = useParams();
    const { t } = useTranslation();

    const randomColor = useCallback(() => {
        return Math.floor(Math.random() * 16777215).toString(16);
    }, [])

    function decodeHTMLEntities(text) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    }

    const fetchJobs = useCallback(() => {
        const url = "https://sigmaheat.jobs.personio.de/xml";
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                const xml = this.responseXML;
                let positions = Array.from(xml.getElementsByTagName("position"));

                
                const position = positions.filter((pos) => parseInt(pos.childNodes[1].innerHTML) === parseInt(params.jobid))[0];
                const codeTable = [...position.querySelectorAll("jobDescription")];
                const jobDescritionArray = [];


                codeTable.forEach(table => {
                    const cdataContent = table.querySelector('value').textContent;

                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = cdataContent;

                    const sentences = Array.from(tempDiv.querySelectorAll('li')).map(li => {
                        const strong = li.querySelector('strong');
                        // const point = strong ? strong.textContent : 'Point';
                        const point = strong ? strong.textContent : '';
                        const description = li.textContent.replace(strong?.textContent, '').trim();
                        return `${point} ${description}`;
                    });

                    if (sentences.length === 0) {
                        const valueText = cdataContent.trim();
                        const cdataStart = '<![CDATA[';
                        const cdataEnd = ']]>';
                        let extractedContent = valueText;
                        if (valueText.startsWith(cdataStart) && valueText.endsWith(cdataEnd)) {
                            extractedContent = valueText.substring(
                                cdataStart.length,
                                valueText.length - cdataEnd.length
                            );
                        }

                        tempDiv.innerHTML = extractedContent;

                        function removeStyles(element) {
                            element.removeAttribute('style');
                            Array.from(element.children).forEach(child => removeStyles(child));
                        }

                        removeStyles(tempDiv)
                    }

                    jobDescritionArray.push({
                        name: table.querySelector("name").innerHTML,
                        html: sentences.length === 0 ? [tempDiv.innerHTML] : sentences
                    });
                });

                /* Other Jobs */
                // positions.sort((a, b) => {
                //     const dateA = Date.parse(a.getElementsByTagName("createdAt")[0].innerHTML);
                //     const dateB = Date.parse(b.getElementsByTagName("createdAt")[0].innerHTML);
                //     return dateB - dateA;
                // });

                const jobsArray = [];
                const departments = [];

                departments['Other'] = {name: 'Other', id: 'Other', color: 'lightgrey'};
                
                positions.forEach(position => {
                    if (jobsArray.length > 2 || parseInt(position.getElementsByTagName("id")[0].innerHTML) === parseInt(params.jobid)) return;
                    let department = position.getElementsByTagName("department");
                    if (department !== undefined && department.length > 0) {
                        department = department[0].innerHTML;
                        departments[department] = {
                            name: department,
                            id: department,
                            color: randomColor()
                        };
                    } else {
                        department = 'Other';
                    }

                    let locations = [];
                    let office = position.getElementsByTagName("office");
                    let additionalOffices = position.getElementsByTagName("additionalOffices")[0];

                    if (office !== undefined && office.length > 0) {
                        office = office[0].innerHTML;
                        locations.push(office);
                        if (additionalOffices !== undefined) {
                            additionalOffices = additionalOffices.getElementsByTagName("office");
                            [...additionalOffices].forEach(item => {
                                locations.push(item.innerHTML);
                            })
                        }
                    }

                    console.log("position: ", position)
                    
                    jobsArray.push({
                        id: parseInt(position.getElementsByTagName("id")[0].innerHTML),
                        tag: departments[department],
                        title: decodeHTMLEntities(position.getElementsByTagName("name")[0].innerHTML),
                        position: position.getElementsByTagName("schedule")[0].innerHTML,
                        locations: locations,
                        recruiting: position.getElementsByTagName("recruitingCategory")[0]?.innerHTML
                    })
                })

                setJobs(jobsArray);

                let locations = [];
                let office = position.getElementsByTagName("office");
                let additionalOffices = position.getElementsByTagName("additionalOffices")[0];

                if (office !== undefined && office.length > 0) {
                    office = office[0].innerHTML;
                    locations.push(office);
                    if (additionalOffices !== undefined) {
                        additionalOffices = additionalOffices.getElementsByTagName("office");
                        [...additionalOffices].forEach(item => {
                            locations.push(item.innerHTML);
                        })
                    }
                }

                console.log("position: ", position)

                setJob({
                    id: position.querySelector("id").innerHTML,
                    tag: departments[position.querySelector("department")?.innerHTML || 'Other'],
                    title: position.querySelector("name").innerHTML,
                    position: position.querySelector("schedule").innerHTML,
                    locations: locations,
                    recruiting: position.getElementsByTagName("recruitingCategory")[0]?.innerHTML,
                    jobDescription: jobDescritionArray
                });
            }
        };
        xhttp.open("GET", url);
        xhttp.send();
    }, [params.jobid, randomColor]);

    useEffect(() => {
        if (!params) return;
        fetchJobs();
    }, [params, fetchJobs])

    return (
        <section className='job__wrapper'>
            <div className='job__left__side'>
                <div className='job__card'>
                    <div className='job__card__top'>
                        <h1 className='job__title'>{job?.title}</h1>
                        <h1 className='job__subtitle'>{`${job?.recruiting !== undefined ? job?.recruiting + ', ' : ''}${t(job?.position)} · ${job?.locations.join(', ')}`}</h1>
                    </div>
                    <div className='job__card__bottom'>
                        <Link className='job__apply__button' to={'/jobapply/'+params.jobid}>{t('applyNow')}</Link>
                    </div>
                </div>
                <FontAwesomeIcon className='scroll__down' icon={faArrowDown} />
            </div>

            <div className='job__right__side'>
                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{job?.jobDescription[0]?.name}</p>
                    <ul className='job__list'>
                        {/* {Boolean(job !== undefined) && job?.jobDescription[0]?.html.map((bullet, index) => <p key={`job-item-${index}`} className='job__description job__list__item' dangerouslySetInnerHTML={{ __html: bullet }} />)} */}
                        {Boolean(job !== undefined) && job?.jobDescription[0]?.html.map((bullet, index) => <li key={`job-item-${index}`} className='job__description job__list__item'>{bullet}</li>)}
                    </ul>

                </div>
                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{job?.jobDescription[1]?.name}</p>
                    <ul className='job__list'>
                        {Boolean(job !== undefined) && job?.jobDescription[1]?.html.map((bullet, index) => <li key={`job-item-${index}`} className='job__list__item'>{bullet}</li>)}
                    </ul>
                </div>
                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{job?.jobDescription[2]?.name}</p>
                    <ul className='job__list'>
                        {Boolean(job !== undefined) && job?.jobDescription[2]?.html.map((bullet, index) => <li key={`job-item-${index}`} className='job__list__item'>{bullet}</li>)}
                    </ul>
                </div>
                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{job?.jobDescription[3]?.name}</p>
                    <ul className='job__list'>
                        {Boolean(job !== undefined) && job?.jobDescription[3]?.html.map((bullet, index) => parse(`<div key={job-item-${index}} className='job__description'>${bullet}</div>`))}
                    </ul>
                </div>
                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{job?.jobDescription[4]?.name}</p>
                    {Boolean(job !== undefined) && job?.jobDescription[4]?.html.map((bullet, index) => {parse(`<div key={job-item-${index}} className='job__description'>${bullet}</div>`)})}
                </div>

                <Link className='job__apply__button' to={`/jobapply/${params.jobid}`}>{t('applyNow')}</Link>

                {/* <div className='job__list__wrapper'>
                    <p className='job__list__heading'>Our global benefits</p>
                    <ul className='job__list'>
                        <li className='job__list__benefit'>
                            <img src={WorkingHours} alt='' />
                            <p><span>flexible Arbeitszeiten</span>
                            <span style={{display: 'block'}}>Bei uns kannst du dir deine Arbeitszeit individuell festlegen. Darunter fällt die tägliche Arbeitszeit, dein wöchentliches Pensum und die Anzahl der Arbeitstage.</span></p>
                        </li>
                        <li className='job__list__benefit'>
                            <img src={DevelopmentPotential} alt='' />
                            <p><span>hohes Entwicklungspotenzial</span>
                            <span style={{display: 'block'}}>Nicht nur fachlich wächst du jeden Tag bei SigmaHeat, sondern auch persönlich. An jeder Herausforderung kannst du über sich selbst hinauswachsen, wenn du das willst.</span></p>
                        </li>
                        <li className='job__list__benefit'>
                            <img src={Teamevents} alt='' />
                            <p><span>regelmäßige Teamevents</span>
                            <span style={{display: 'block'}}>Jeden zweiten Mittwoch gibt es bei uns ein Teamevent, sei es auf ein Bierchen im Biergarten oder Lasertag. Das entscheiden wir zusammen</span></p>
                        </li>
                        <li className='job__list__benefit'>
                            <img src={Creativedays} alt='' />
                            <p><span>Creative Days</span>
                            <span style={{display: 'block'}}>Du hast ein Problem, mit einem Prozess, einer Aufgabe oder einem ganz anderen Thema? Dann sprich es auf unserem monatlichen Creative Day an und wir finden eine Lösung.</span></p>
                        </li>
                        <li className='job__list__benefit'>
                            <img src={Hero} alt='' />
                            <p><span>Sei ein Klimaheld</span>
                            <span style={{display: 'block'}}>Durch die Zusammenarbeit mit SigmaHeat und den SigmaHeat Energieservices kannst du mit deiner Arbeit bis zu 1000 Tonnen CO2 pro Jahr einsparen. Dein zukünftiges Ich wird es dir danken!</span></p>
                        </li>
                    </ul>
                </div> */}

                <div className='job__list__wrapper'>
                    <p className='job__list__heading'>{t('similarJobs')}</p>
                    <ul className="jobs__list">
                        {jobs.map((j, index) => {
                            return (
                                <li className="job__position" key={`job-item-${index}`}>
                                    <Link to={`/job/${j.id}`}>
                                        <h3 className="job__name">{j.title}</h3>
                                    </Link>
                                    <span className="job__office">{`${j?.recruiting !== undefined ? j?.recruiting + ', ' : ''}${t(j.position)} · ${j.locations.join(', ')}`}</span>
                                    {/* <button className="job__type" style={{backgroundColor: `#${j.tag.color}`, borderColor: `#${j.tag.color}`}}>{j.tag.name}</button> */}
                                </li>
                            )
                        })}
                    </ul>
                    {/* <Link to={'/jobs'} className='josb__button'>{t('seeAllJobs')}</Link> */}
                </div>
            </div>
        </section>
    )
}

export default JobLayout